<template>
  <div>
    <!------------ START: FieldWrapper ------------>
    <FieldWrapper v-if="editor" :field="field">
      <div class="form-control px-1 h-auto" :class="{ disabled: isDisabled }">
        <!------------ START: Editor toolbar ------------>
        <div class="px-3">
          <EditorToolbar :editor="editor" />
        </div>
        <!------------ END: Editor toolbar ------------>
        <div v-if="!isDisabled" class="separator separator-solid my-2" />
        <!------------ START: Editor content ------------>
        <div class="px-3 mb-n3">
          <EditorContent :editor="editor" />
        </div>
        <!------------ END: Editor content ------------>
        <!------------ START: Editor validation errors ------------>
        <div
          v-if="validationErrors.length"
          class="separator separator-solid mb-2 mt-5"
        />
        <ul v-if="validationErrors.length" class="mx-3 mb-0">
          <li
            v-for="(error, i) in validationErrors"
            :key="i"
            class="text-nowrap text-left text-danger"
          >
            {{ error }}
          </li>
        </ul>
        <!------------ END: Editor validation errors ------------>
      </div>
    </FieldWrapper>
    <!------------ END: FieldWrapper ------------>
  </div>
</template>

<script>
import FieldWrapper from "@/components/Tools/FormHelper/Components/FieldWrapper";
import {
  base,
  eagerValidation
} from "@/components/Tools/FormHelper/Helper/mixins";

import EditorToolbar from "@/components/Tools/FormHelper/Components/Editor/EditorToolbar";
import { Editor, EditorContent } from "@tiptap/vue-2";

import StarterKit from "@tiptap/starter-kit";

export default {
  components: { EditorContent, FieldWrapper, EditorToolbar },
  mixins: [base, eagerValidation],
  props: {},
  data() {
    return {
      editor: null
    };
  },
  computed: {},
  watch: {
    isDisabled: function () {
      this.setEditable();
    }
  },
  mounted() {
    this.initEditor();
    this.setEditable();
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {
    initEditor() {
      this.editor = new Editor({
        content: this.value,
        extensions: [StarterKit],
        onUpdate: () => {
          // For validation get text only
          this.$v.value.$model = this.editor.getText();
          // For updating model get text as html
          this.$emit("input", this.editor.getHTML());
        }
      });
    },
    setEditable() {
      // Set if editor is editable
      this.editor.setEditable(!this.isDisabled);
    }
  }
};
</script>

<style lang="scss">
.ProseMirror {
  outline: none;
}
</style>
